.FunFacts-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100vw;
}
.FunFacts-section h1 {
  padding-left: 1rem;
  margin-bottom: 0;
}
.FunFacts-section span {
  margin-left: 1rem;
}

.container {

  display: flex;
  white-space: nowrap;
  width: 100%;
  height: clamp(3em, 10vw, 6em);
  overflow: hidden;
  font-family: sans-serif;

}


.loop-text {

  /* font-size: clamp(2.5rem, 8vw, 5.5rem);
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0.25em; */

}

.loop-text-first {
  animation: loopText 4s infinite reverse linear;      
}

.loop-text-second {
  animation: loopText 5.5s infinite linear;      
}

.loop-text-third {
  animation: loopText 5s infinite reverse linear;      
}

@keyframes loopText {

from {
    transform: translateX(0);
 }

to {
    transform: translateX(-100%);
 }

}

#scroll-container {
  border: 3px solid black;
  border-radius: 5px;
  height: 350px;
  overflow: hidden;
}

#scroll-text {
  height: 100%;
  text-align: center;
  

  
  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  
  -moz-animation: my-animation 35s linear infinite;
  -webkit-animation: my-animation 35s linear infinite;
  animation: my-animation 35s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateY(100%); }
  to { -moz-transform: translateY(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateY(100%); }
  to { -webkit-transform: translateY(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}