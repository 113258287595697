.urbanism-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100vw;
}

.urbanism-section h1 {
  padding-left: 1rem;
  margin-bottom: 0;
}
.urbanism-section span {
  margin-left: 1rem;
}
@media screen and (min-width: 900px) {
  .urbanism-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 1rem;
  }
  .urbanism-image-area img {
    border-radius: 0%;
    width: 27rem;
    height: 18rem;
  }
  .urbanism-section p {
    margin: 1rem;
    border-left: 1px solid black;
  }
  .urbanism-contact-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly
  }
  .urbanism-contact-info img {
    max-width: 3rem;
    max-height: 3rem;
    margin-left: 2rem;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .urbanism-info {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .urbanism-image-area img {
    border-radius: 50%;
    max-width: 75vw;
    max-height: 75vw;
    padding: 1rem;
    padding-bottom: 0;
  }
  .urbanism-image-area {
    padding: 0.5rem;
  }
  .urbanism-section p {
    border-top: 1px solid black;
    max-width: 75vw;
    padding-bottom: 0rem;
  }
  .urbanism-contact-info {
    display: flex;
  }
  .urbanism-contact-info img {
    margin: 1rem;
    max-width: 10vw;
    max-height: 10vw;
  }
}
.urbanism-image-area {
  margin-left: 2rem;
  margin-right: 2rem;
}

.urbanism-image-and-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}